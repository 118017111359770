import Framework7 from 'framework7';
import { Router } from 'framework7/modules/router/router';

import { NotFoundPage } from './pages/404';
import { DebugPage } from './pages/DebugPage';
import { FavoritesPage } from './pages/FavoritesPage';
import { Home } from './pages/Home';
import { ordersRoutes } from './pages/MyOrdersPage';
import { NotAvailablePage } from './pages/NotAvailablePage';
import { sellerAreaRoutes } from './pages/SellerAreaPage/routes';

import { WishListPage } from './pages/WishListPage';
import { AuthActions } from './shared/constants';

export interface RouteParameters extends Router.RouteParameters {
    app?: Framework7;
}

const routes: RouteParameters[] = [
    {
        name: 'HomePage',
        path: '/',
        async: (_to, _from, resolve) => import('./pages/Home').then((rc) => resolve({ component: rc.Home })),
    },
    {
        name: 'Marketplace',
        path: '/market',
        component: Home,
    },
    {
        name: 'Marketplace',
        path: '/marketspace',
        component: Home,
    },
    {
        name: AuthActions.REGISTER,
        path: '/register',
        options: {
            props: {
                authAction: AuthActions.REGISTER,
            },
        },
        async: (_to, _from, resolve) => import('./pages/Home').then((rc) => resolve({ component: rc.Home })),
    },
    {
        name: AuthActions.LOGIN,
        path: '/login',
        options: {
            props: {
                authAction: AuthActions.LOGIN,
            },
        },
        async: (_to, _from, resolve) => import('./pages/Home').then((rc) => resolve({ component: rc.Home })),
    },
    {
        name: AuthActions.VERIFY_EMAIL,
        path: '/confirm-email',
        options: {
            props: {
                authAction: AuthActions.VERIFY_EMAIL,
            },
        },
        async: (_to, _from, resolve) => import('./pages/Home').then((rc) => resolve({ component: rc.Home })),
    },
    {
        name: 'NotAvailable',
        path: '/not-available',
        component: NotAvailablePage,
    },
    {
        name: 'PromotionPage',
        path: '/promo/:promotionPageUid',
        async: (_to, _from, resolve) =>
            import('./pages/SearchResultsPage').then((rc) => resolve({ component: rc.SearchResultsPage })),
    },
    {
        name: 'HomePage_Category',
        path: '/category/:catid/subcategory/:subcatid',
        component: Home,
    },
    {
        path: '/store/:storeId',
        async: (_to, _from, resolve) => import('./pages/StorePage').then((rc) => resolve({ component: rc.StorePage })),
    },
    {
        path: '/store/edit/:storeId',
        async: (_to, _from, resolve) =>
            import('./pages/StoreHomepageConstructorPage').then((rc) =>
                resolve({ component: rc.StoreHomepageConstructorPage }),
            ),
    },
    {
        path: '/store/published/:storeId',
        async: (_to, _from, resolve) =>
            import('./pages/StoreHomepageConstructorPage/HomepagePublishedPage').then((rc) =>
                resolve({ component: rc.HomepagePublishedPage }),
            ),
    },
    {
        path: '/no-products-yet',
        async: (_to, _from, resolve) =>
            import('./pages/NoProductsYetPage').then((rc) => resolve({ component: rc.NoProductsYetPage })),
    },
    {
        path: '/product-details/:uid',
        async: (_to, _from, resolve) =>
            import('./pages/ProductDetailsPage').then((rc) => resolve({ component: rc.ProductDetailsPage })),
    },
    {
        path: '/cart/',
        async: (_to, _from, resolve) => import('./pages/CartPage').then((rc) => resolve({ component: rc.CartPage })),
    },
    {
        path: '/checkout',
        async: (_to, _from, resolve) =>
            import('./pages/CheckoutPage').then((rc) => resolve({ component: rc.CheckoutPage })),
    },
    {
        path: '/payment-result/:orderUid',
        async: (_to, _from, resolve) =>
            import('./pages/PaymentResultPage').then((rc) => resolve({ component: rc.PaymentResultPage })),
    },
    {
        path: '/profile',
        async: (_to, _from, resolve) =>
            import('./pages/ProfilePage').then((rc) => resolve({ component: rc.ProfilePage })),
        routes: [
            {
                path: '/personal',
                async: (_to, _from, resolve) =>
                    import('./pages/MyPersonalPage').then((rc) => resolve({ component: rc.MyPersonalPage })),
                routes: [
                    {
                        path: '/settings',
                        async: (_to, _from, resolve) =>
                            import('./pages/ProfileSettingsPage').then((rc) =>
                                resolve({ component: rc.ProfileSettingsPage }),
                            ),
                    },
                    {
                        path: '/shipping-addresses',
                        async: (_to, _from, resolve) =>
                            import('./pages/ShippingAddressesPage').then((rc) =>
                                resolve({ component: rc.ShippingAddressesPage }),
                            ),
                    },
                    {
                        path: '/transactions',
                        async: (_to, _from, resolve) =>
                            import('./pages/TransactionsPage').then((rc) =>
                                resolve({ component: rc.TransactionsPage }),
                            ),
                    },
                    {
                        path: '/reviews',
                        async: (_to, _from, resolve) =>
                            import('./pages/MyReviewsPage').then((rc) => resolve({ component: rc.MyReviewsPage })),
                    },
                    {
                        path: '/wallet',
                        async: (_to, _from, resolve) =>
                            import('./pages/Wallet/WalletPage').then((rc) => resolve({ component: rc.WalletPage })),
                        routes: [
                            {
                                path: '/cards',
                                async: (_to, _from, resolve) =>
                                    import('./pages/Wallet/CardsPage').then((rc) =>
                                        resolve({ component: rc.CardsPage }),
                                    ),
                            },
                            {
                                path: '/currencies',
                                async: (_to, _from, resolve) =>
                                    import('./pages/Wallet/CurrenciesPage').then((rc) =>
                                        resolve({ component: rc.CurrenciesPage }),
                                    ),
                            },
                        ],
                    },
                    {
                        path: '/verify',
                        async: (_to, _from, resolve) =>
                            import('./pages/PassportPage').then((rc) => resolve({ component: rc.PassportPage })),
                    },
                    ...ordersRoutes,
                ],
            },
            {
                path: '/seller-area',
                async: (_to, _from, resolve) =>
                    import('./pages/SellerAreaPage').then((rc) => resolve({ component: rc.SellerAreaPage })),
                routes: sellerAreaRoutes,
            },
        ],
    },
    {
        path: '/wish-list',
        component: WishListPage,
    },
    {
        path: '/favorites',
        component: FavoritesPage,
    },
    {
        name: 'SearchResults',
        path: '/search/',
        async: (_to, _from, resolve) =>
            import('./pages/SearchResultsPage').then((rc) => resolve({ component: rc.SearchResultsPage })),
    },
    { path: '/debug', component: process.env.DEBUG_ENABLED === 'true' ? DebugPage : NotFoundPage },
    {
        path: '/article',
        async: (_to, _from, resolve) =>
            import('./pages/ArticlesPage/ArticlesPage').then((rc) => resolve({ component: rc.ArticlesPage })),
    },
    {
        path: '/article/:articleUid',
        async: (_to, _from, resolve) =>
            import('./pages/ArticleDetailsPage').then((rc) => resolve({ component: rc.ArticleDetailsPage })),
    },
    {
        path: '(.*)',
        component: NotFoundPage,
    },
];

export default routes;
