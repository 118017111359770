import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { messengerService } from '@/api/messengerService';

import { IcBack } from '@/assets';
import { useAppSelector } from '@/hooks/store';
import { MSButtonIcon } from '@/shared/UIKit/ButtonIcon/ButtonIcon';
import { ProductType } from '@/types/productType';

import './NavbarTopMenu.less';

export type NavbarTopMenuProps = {
    onChangeProductType: (productType: ProductType) => void;
};

export const NavbarTopMenu = ({ onChangeProductType }: NavbarTopMenuProps): JSX.Element => {
    const { t } = useTranslation();

    const { activeProductType } = useAppSelector((state) => state.rootReducer);

    const onChangeProductTypeHandle = (productType: ProductType) => {
        onChangeProductType(productType);
    };

    return (
        <div className="navbar-top-menu">
            {messengerService.enabled && (
                <MSButtonIcon
                    className="navbar-top-menu__item-back-to-messenger__button"
                    onClick={messengerService.goToMessenger}
                    icon={<IcBack className="navbar-top-menu__item-back-to-messenger__icon"></IcBack>}
                ></MSButtonIcon>
            )}
            <div
                className={cn('navbar-top-menu__item', { active: activeProductType === ProductType.Product })}
                onClick={() => onChangeProductTypeHandle(ProductType.Product)}
            >
                <span>{t('main_page.space_selector.button.products')}</span>
            </div>
            <div
                className={cn('navbar-top-menu__item', { active: activeProductType === ProductType.Advertisement })}
                onClick={() => onChangeProductTypeHandle(ProductType.Advertisement)}
            >
                <span>{t('main_page.space_selector.button.offers')}</span>
            </div>
            <div
                className={cn('navbar-top-menu__item', { active: activeProductType === ProductType.Service })}
                onClick={() => onChangeProductTypeHandle(ProductType.Service)}
            >
                <span>{t('main_page.space_selector.button.services')}</span>
            </div>
        </div>
    );
};

export default NavbarTopMenu;
